import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

// import imgContent1 from "../../assets/image/jpeg/easy-image-2-1.jpg";
// import imgContent2 from "../../assets/image/jpeg/easy-image-2-2.jpg";

const ContentImg = styled(Box)`
  box-shadow: ${({ theme }) => `0 42px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  overflow: hidden;
  max-height: 515px;
`;

const Content = () => (
  <>
    <Section>
      <Container>
        <Row className="justify-content-center pb-4">
          <Col lg="12" className="pl-lg-5">
            <Text>
              We developed Kesto to solve a need our team had and soon realized our friends and family had the same need. We did some research and found out some regional business information is provided by some services including in print form. Finding Ethiopians & Eritreans travel agent, restaurant or grocery store was difficult especially when you were an out of town visitor. If you did not have a local contact e.g. finding the best place to have Ethiopian food was possible but difficult.
            </Text>
          </Col>
        </Row>

        <Row className="justify-content-center pb-4">
          <Col lg="12" className="pl-lg-5">
            <Text>
              By creating Ethiopians & Eritreans “Yelp” that aggregates business, professionals, events and other listings we solve the simple question “what is where and how can I either contact them or get there?”
            </Text>
          </Col>
        </Row>

        <Row className="justify-content-center pb-4">
          <Col lg="12" className="pl-lg-5">
            <Text>
              We are working on adding new and exciting functionalities into the app. Sign up to our mailing list to be notified! 
            </Text>
          </Col>
        </Row>

        <Row className="justify-content-center pb-4">
          <Col lg="12" className="pl-lg-5">
            <Text>
              Please use “Contact us” for questions and inquiries
            </Text>
          </Col>
        </Row>

        <Row className="justify-content-center pb-4">
          <Col lg="12" className="pl-lg-5">
            <Text>
              Kesto is developed by Jotika LLC, a Mobile, Web and Data consulting company based in Silicon Valley
            </Text>
          </Col>
        </Row>

      </Container>
    </Section>
  </>
);

export default Content;
